var _cb = _cb || [];
_cb.push(["setSourceHash", "632065e5e10e80.69553894"]);
_cb.push(["setPrivacyLevel", "allowTracking"]);
_cb.push(["setEndpoint", "https://app.conversation24.com/sdk/"]);
_cb.push(["setDataEndpoint", "https://app.conversation24.com/"]);
_cb.push(["setDefaultCss", true]);
_cb.push(["addPageview", true]);
_cb.push(["setAutoLoad", true]);
_cb.push(["pollAvailability", 7500]);
_cb.push(["piwikProID", "false"]);
window._cb = _cb;
