import { Reducer } from "redux";
import { ActiveRegelingActions } from "./actions";
import * as types from "./actionTypes";
import { IActiveRegelingState } from "./state";

export const initialActiveRegelingenState: IActiveRegelingState = {
  activeRegeling: "",
};

export const ActiveRegelingReducer: Reducer<IActiveRegelingState, ActiveRegelingActions> = (
  state: IActiveRegelingState = initialActiveRegelingenState,
  action: ActiveRegelingActions
): IActiveRegelingState => {
  if (action.type === types.SET_ACTIVEREGELING) {
    return {
      activeRegeling: action.activeRegeling.activeRegeling,
    };
  }

  return state;
};
