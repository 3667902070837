import { z } from 'zod';

const envSchema = z.object({
  API: z.string().min(1),
  APPLICATION_PATH: z.string().min(1),
  BUILD_PCKG_VERSION: z.string().min(1),
  AUTH_ENABLED: z.boolean().default(false),
  RUM_ENABLED: z.boolean().default(false),
  RUM_APP_NAME: z.string().min(1).default('mijncak-piral'),
  RUM_TOKEN: z.string().min(1).default('dummyToken'),
  RUM_DEPLOYMENT_ENV: z.string().min(1).default('mijncak-local'),
});

const env = envSchema.parse(process.env);

export default env;
