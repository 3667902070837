import * as React from "react";
import { connect } from "react-redux";
import { Menu, MenuItemProps } from "piral";
import { Navbar, Nav } from "react-bootstrap";
import { RegelingNaamEnum } from "@mijncak/shared";
import { handleOpenMenu, initKeyboardFocus } from "../utils/CAK.Utils";
import { useAuthenticationServiceContext } from "../authentication/context/UseAuthenticationServiceContext";
import { useApplicationContext } from "./context/UseApplicationContext";
import { ApplicationState } from "../store";
import { RegelingenDropdown } from "./RegelingenDropdown";
import { RegelingDomainModel } from "../plugins/regelingUrl/domain/RegelingDomainModel";
import env from "../env";

export const MenuItem: React.FC<MenuItemProps> = ({ children }) => <>{children}</>;

const ConnectedMenu = (props: IStateFromProps) => {
  initKeyboardFocus();
  const [isNavCollapsed, setIsNavCollapsed] = React.useState(false);

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    handleOpenMenu(!isNavCollapsed);
  };

  const handleNavCollapseOnBreakpoint = () => {
    setIsNavCollapsed(false);
    handleOpenMenu(false);
  };

  const regelingFilter = props.regelingen
    .filter((regeling) => regeling.regelingNaam === props.activeRegeling)
    .map((regeling) => regeling.regelingUrl);

  const regelingHome = regelingFilter.length > 0 ? regelingFilter[0] : env.APPLICATION_PATH;

  const handleMobileMenuAriaHidden = () => {
    const mobileMenuContainer = document.querySelector(".mobile-toggle-menu-container");
    const mobileMenuToggler = document.querySelector(".mobile-toggle-menu-container .navbar-toggler");

    if (mobileMenuContainer) {
      if (mobileMenuToggler && window.getComputedStyle(mobileMenuToggler).display === "none") {
        mobileMenuContainer.setAttribute("aria-hidden", "true");
      } else {
        mobileMenuContainer.setAttribute("aria-hidden", "false");
      }
    }
  };

  const handleMenuControlsOnBreakpointSize = () => {
    const normalMenuViewPoints: string[] = ["lg", "xl"];
    const breakpointDivs = document.getElementById("breakpoint-sizer")?.querySelectorAll("div");
    const currentBreakPoint = breakpointDivs
      ? Array.from(breakpointDivs)
          .find((el) => window.getComputedStyle(el).display !== "none")
          ?.getAttribute("data-size")
      : null;

    // close menu, reset indexes, remove body class on resize Vw "lg" + "xl".
    if (currentBreakPoint && normalMenuViewPoints.includes(currentBreakPoint) && document.querySelector(".openMenu")) {
      handleNavCollapseOnBreakpoint();
    }

    if (currentBreakPoint) {
      handleMobileMenuAriaHidden();
    }
  };

  React.useEffect(() => {
    handleMobileMenuAriaHidden();
  }, []);

  window.addEventListener("resize", handleMenuControlsOnBreakpointSize);
  const authenticationService = useAuthenticationServiceContext();
  const basePath = useApplicationContext();

  return (
    <header>
      <a
        href="#main-menu"
        className="skip-link jump-to-main-menu"
        onClick={(e) => {
          e.preventDefault();

          const query = window.matchMedia("screen and (max-width: 991px)").matches
            ? ".header-nav-container button.navbar-toggler"
            : "a.navbar-brand";

          const navbarHeaderElements = document.querySelectorAll<HTMLElement>(query);

          e.currentTarget.blur();
          if (navbarHeaderElements.length > 0) {
            navbarHeaderElements[0].focus();
            navbarHeaderElements[0].classList.add("keyboard-focus");
          }
        }}
      >
        Naar hoofdnavigatiemenu
      </a>

      <a
        href="#main-content"
        className="skip-link jump-to-main-content"
        onClick={(e) => {
          const scrollTo = (element: HTMLElement, to: number) => {
            const start = element.scrollTop;
            const change = to - start;

            window.scrollTo({
              top: change,
              behavior: "smooth",
            });
          };
          let preventBrowserDefault = false;

          const linkTarget = document.querySelector<HTMLElement>("#main-content");

          if (linkTarget === null) {
            return;
          }

          e.currentTarget.blur();

          scrollTo(document.documentElement, linkTarget.offsetTop - 20);

          if (linkTarget) {
            preventBrowserDefault = true;

            const focusableElement = linkTarget.querySelector<HTMLElement>("a,input,select,textarea,button");

            if (focusableElement) {
              focusableElement.focus();
              focusableElement.classList.add("keyboard-focus");
            }
          }

          if (preventBrowserDefault) {
            e.preventDefault();
          }
        }}
      >
        Naar hoofdinhoud
      </a>
      <div className="container-fluid container-no-padding header-nav-container fixed-top">
        <Navbar
          collapseOnSelect
          expand="lg"
          expanded={isNavCollapsed}
          bg="header-cak"
          variant="light"
          className="container"
          onToggle={handleNavCollapse}
          role="navigation"
          aria-label="Main navigation"
          id="main-menu"
        >
          <Navbar.Brand href="#main-menu" aria-hidden="false" tabIndex={0} id="header-navbar-brand">
            <img src={`${basePath}/images/cak-logo.png`} alt="Logo C.A.K" />
          </Navbar.Brand>
          <div className="mobile-toggle-menu-container" aria-hidden>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" aria-haspopup="true" id="mobile-toggle-menu">
              <span className="sr-only">Open navigatie</span>
              <span className="icon-bar" aria-hidden />
              <span className="icon-bar" aria-hidden />
              <span className="icon-bar" aria-hidden />
              <span className="icon-text">Menu</span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="mobileclosebutton" id="mobile-close-button">
              <span className="sr-only">Sluit navigatie</span>
              <img src={`${basePath}/images/close-white.svg`} alt="Sluit navigatie menu" aria-hidden="true" />
            </Navbar.Toggle>

            <Nav className="mijn-cak-menu mr-auto" id="mijn-cak-nav-list" role="menu">
              {props.activeRegeling && <Menu type="header" />}
            </Nav>
            <Nav className="user-menu ml-auto" id="user-nav-list" role="menu">
              {props.activeRegeling && (
                <>
                  <RegelingenDropdown regelingen={props.regelingen} activeRegeling={props.activeRegeling} />
                  <Nav.Link href={regelingHome} role="menuitem" id="menu-item-user" title={props.userName}>
                    <img
                      src={`${basePath}/images/gebruiker-pink.svg`}
                      className="user-menu-icon"
                      aria-hidden="true"
                      alt=""
                    />
                    <img
                      src={`${basePath}/images/gebruiker-white.svg`}
                      className="user-menu-icon-mobile"
                      aria-hidden="true"
                      alt=""
                    />
                    {props.userName}
                  </Nav.Link>
                </>
              )}
              <Nav.Link
                id="menu-item-logout"
                eventKey={2}
                href="#"
                role="menuitem"
                onClick={() => {
                  if (authenticationService) {
                    authenticationService.logout().then((result) => {
                      window.location.href = result.returnUrl ?? "/";
                    });
                  }
                }}
              >
                <img src={`${basePath}/images/slotje-pink.svg`} className="user-menu-icon" aria-hidden="true" alt="" />
                <img
                  src={`${basePath}/images/slotje-white.svg`}
                  className="user-menu-icon-mobile"
                  aria-hidden="true"
                  alt=""
                />
                Uitloggen
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="container-fluid home-seperator">
        <div className="row line" />
      </div>
    </header>
  );
};

interface IStateFromProps {
  userName: string;
  regelingen: RegelingDomainModel[];
  activeRegeling: RegelingNaamEnum | "";
}

const mapStateToProps = (state: ApplicationState): IStateFromProps => ({
  userName: state.gebruikersNaam.naam,
  regelingen: state?.regelingen?.regelingen ?? [],
  activeRegeling: state?.activeRegeling?.activeRegeling ?? [],
});

export const MenuContainer = connect<IStateFromProps>(mapStateToProps)(ConnectedMenu);
