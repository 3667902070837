import { PiralPlugin } from "piral-core";
import { Dispatch } from "redux";
import { setRegelingUrl } from "../store/actions";

interface RegelingUrlConfig {
  dispatch: Dispatch;
}

export interface PiletRegelingUrlApi {
  setRegelingUrl(regelingUrl?: string, regelingNaam?: string): void;
}

export function createRegelingUrlApi(config: RegelingUrlConfig): PiralPlugin<PiletRegelingUrlApi> {
  return (_context) =>
    (_api, _target): PiletRegelingUrlApi => ({
      setRegelingUrl(regelingUrl: string | "", regelingNaam: string | ""): void {
        config.dispatch(setRegelingUrl(regelingUrl, regelingNaam));
      },
    });
}
