import { createStore } from "redux-dynamic-modules";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import allReducers from "./store";

export function createMijnCAKStore() {
  return createStore(
    {
      initialState: {},
      extensions: [getThunkExtension()],
      enhancers: [],
    },
    {
      id: "mijncak-store",
      reducerMap: allReducers,
      initialActions: [],
      finalActions: [],
    }
  );
}
