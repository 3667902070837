import { PiralPlugin } from "piral-core";
import { Dispatch } from "redux";
import { setGebruikersNaam } from "../store/gebruikersnaam/actions";

interface GebruikersNaamConfig {
  dispatch: Dispatch;
}

export interface PiletGebruikersNaamApi {
  setGebruikersNaam(naam?: string): void;
}

export function createGebruikersNaamApi(config: GebruikersNaamConfig): PiralPlugin<PiletGebruikersNaamApi> {
  return (_context) =>
    (_api, _target): PiletGebruikersNaamApi => ({
      setGebruikersNaam(naam: string | ""): void {
        config.dispatch(
          setGebruikersNaam({
            naam
          })
        );
      },
    });
}
