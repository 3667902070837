import { GebruikersNaamDomainModel } from "../../domain/GebruikersNaamDomainModel";
import * as types from "./actionTypes";

export type GebruikersNaamActions = {
  type: typeof types.SET_GEBRUIKERSNAAM;
  gebruikersNaam: GebruikersNaamDomainModel;
};

export function setGebruikersNaam(gebruikersNaam: GebruikersNaamDomainModel): GebruikersNaamActions {
  return {
    type: types.SET_GEBRUIKERSNAAM,
    gebruikersNaam
  };
}
