import { RegelingNaamEnum } from "@mijncak/shared";
import * as React from "react";
import { RegelingDomainModel } from "../plugins/regelingUrl/domain/RegelingDomainModel";

interface RegelingDropdownProps {
  regelingen: RegelingDomainModel[];
  activeRegeling: RegelingNaamEnum | "";
}

export const RegelingenDropdown = (props: RegelingDropdownProps): JSX.Element => {
  const [toggle, setToggle] = React.useState(false);
  const regelingText = "Regeling";

  if (props.regelingen && props.regelingen.length > 1) {
    return (
      <div className="menu-item-dropdown nav-link">
        {props.regelingen &&
          props.regelingen.map((regeling, index) => {
            if (regeling.regelingNaam === props.activeRegeling) {
              return (
                <button
                  key={index}
                  className={`nav-link ${toggle ? "nav-link--open" : ""}`}
                  type="button"
                  id="dropdown-nav-toggle"
                  onClick={() => setToggle(!toggle)}
                >
                  {`${regelingText} ${regeling.regelingNaam}`}
                </button>
              );
            }

            return <React.Fragment key={index} />;
          })}
        <ul className={`dropdown ${toggle ? "dropdown--open" : ""}`} id="menu-dropdown-regelingen">
          {props.regelingen &&
            props.regelingen.map((regeling, index) => {
              if (regeling.regelingNaam === props.activeRegeling) {
                return <React.Fragment key={index} />;
              }

              if (regeling.regelingNaam.length > 0 && regeling.regelingUrl.length > 0) {
                return (
                  toggle && (
                    <li
                      className={`menu-item-regeling regeling-${index}`}
                      id={`value-${regeling.regelingNaam}`}
                      key={index}
                    >
                      {regeling && <a href={regeling.regelingUrl}>{`${regelingText} ${regeling.regelingNaam}`}</a>}
                      {!regeling.regelingUrl && `${regelingText} ${regeling.regelingNaam}`}
                    </li>
                  )
                );
              }

              return <></>;
            })}
        </ul>
      </div>
    );
  }

  return <></>;
};
