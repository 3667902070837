import * as React from "react";

interface IRegelingenProviderProps {
  children: React.ReactNode;
  regelingen: string[] | null;
}

export const RegelingenContext = React.createContext<string[] | null>(null);

export const RegelingenContextProvider = (props: IRegelingenProviderProps) => (
  <RegelingenContext.Provider value={props.regelingen}>{props.children}</RegelingenContext.Provider>
);
