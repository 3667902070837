import * as React from "react";
import { CookieContainer } from "./CookieContainer";
import { FooterContainer } from "./FooterContainer";

export const MainContentContainer = ({ children }) => (
  <>
    <main role="main" id="main-content" className="container container-no-padding">
      {children}
    </main>

    <FooterContainer />

    <CookieContainer />

    <div id="breakpoint-sizer" aria-hidden="true">
      <div className="d-block d-sm-none d-md-none d-lg-none d-xl-none" data-size="xs" />
      <div className="d-none d-sm-block d-md-none d-lg-none d-xl-none" data-size="sm" />
      <div className="d-none d-sm-none d-md-block d-lg-none d-xl-none" data-size="md" />
      <div className="d-none d-sm-none d-md-none d-lg-block d-xl-none" data-size="lg" />
      <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block" data-size="xl" />
    </div>
  </>
  );
