import { RegelingEnum } from "@mijncak/shared";
import { AuthenticationService } from "./authentication/service/AuthenticationService";
import { RegelingenService } from "./regelingen/service/RegelingenService";
import { RenderMijnCAK } from "./render";
import { GetAllFeaturesService } from "./authentication/service/AllToggleFeaturesService";
import { renderMaintenanceModePage } from "./renderMaintenanceModePage";
import { FeatureToggleDomainModel } from "./authentication/domain/FeatureToggleDomainModel";
import { renderErrorPage } from "./renderErrorPage";
import { mapErrorToMessage, mapErrorToStatusCode } from "./utils/errorUtils";

function isMijnCAKInOnderhoud(featureToggles: FeatureToggleDomainModel[]) {
  return featureToggles.find((x) => x.name === "mijncak-onderhoud")?.enabled === true;
}

export const authenticate = async (
  authenticationService: AuthenticationService,
  regelingenService: RegelingenService,
  allFeaturesToggleService: GetAllFeaturesService
) => {
  try {
    const [allFeaturesToggle, regelingen] = await Promise.all([
      allFeaturesToggleService.getAllFeatureToggles(),
      regelingenService.getRegelingen(),
    ]);

    if (isMijnCAKInOnderhoud(allFeaturesToggle)) {
      renderMaintenanceModePage(authenticationService);
    } else if (
      !regelingen ||
      regelingen.length === 0 ||
      (regelingen.length === 1 && regelingen[0] === RegelingEnum.WMO_BEFORE_2020)
    ) {
      renderErrorPage(authenticationService, 404);
    } else {
      RenderMijnCAK(regelingen, authenticationService, allFeaturesToggle);
    }
  } catch (error) {
    const statusCode = mapErrorToStatusCode(error);
    const errorMessage = mapErrorToMessage(error);

    if (error?.response?.status === 401) {
      return;
    }

    if (error?.message === errorMessage) {
      renderErrorPage(authenticationService, statusCode);
    }
  }
};
