import * as React from "react";
import { AuthenticationService } from "./authentication/service/AuthenticationService";
import { ContentPage, renderContentPage } from "./ContentPage";

interface MaintenanceModePageProps {
  authenticationService: AuthenticationService;
}

export const MaintenanceModePage = (props: MaintenanceModePageProps) => (
  <ContentPage authenticationService={props.authenticationService}>
    <h1 className="pl-0" data-testid="mijncak-onderhoud-titel">
      Mijn CAK is in onderhoud
    </h1>
    <p className="intro" data-testid="mijncak-onderhoud-content">
      Probeer het later opnieuw.
    </p>
  </ContentPage>
);

export const renderMaintenanceModePage = (service: AuthenticationService) =>
  renderContentPage(<MaintenanceModePage authenticationService={service} />);
