const keyboardFocusClass = "keyboard-focus";

//mijncakapp functies in gebruik
export const initKeyboardFocus = () => {
  document.addEventListener("keyup", (event) => {
    let key = 0;
    const tabKey = 9;

    const localEvent = event || window.event;
    key = localEvent.which || localEvent.keyCode;

    if (key === tabKey) {
      const keyboardFocusElements =
        document.querySelectorAll(".keyboard-focus");
      [].forEach.call(keyboardFocusElements, (el) => {
        el.classList.remove(keyboardFocusClass);
      });

      if (document.activeElement) {
        document.activeElement.classList.add(keyboardFocusClass);
      }
    }

    return true;
  });

  document.querySelectorAll("a,input,button").forEach((el) =>
    el.addEventListener("blur", () => {
      el.classList.remove(keyboardFocusClass);
    })
  );
};

const setBodyClass = (isMenuOpen: boolean) => {
  const body = document.getElementsByTagName("body")[0];
  if (isMenuOpen) {
    body.classList.add("openMenu");
  } else {
    body.classList.remove("openMenu");
  }
};

const setTabIndexes = (isMenuOpen: boolean) => {
  const setTabElements = document.querySelectorAll(
    "#app > main *, #app > nav *"
  );

  if (isMenuOpen) {
    setTabElements.forEach((domElement) => {
      const tabIndex = domElement.getAttribute("tabindex");

      if (tabIndex !== null) {
        domElement.setAttribute("data-save-tabindex", tabIndex);
        domElement.setAttribute("tabindex", "-1");
      } else {
        domElement.setAttribute("tabindex", "-1");
      }
    });
  } else {
    setTabElements.forEach((domElement) => {
      const savedTabIndex = domElement.getAttribute("data-save-tabindex");

      if (savedTabIndex === null) {
        domElement.removeAttribute("tabindex");
      } else {
        domElement.setAttribute("tabindex", savedTabIndex);
      }
    });

    if (document.activeElement) {
      document.activeElement.classList.remove(keyboardFocusClass);
    }
  }
};
export const handleOpenMenu = (isNavCollapsed: boolean) => {
  setBodyClass(isNavCollapsed);
  setTabIndexes(isNavCollapsed);
};
