import { AuthenticationService } from "./authentication/service/AuthenticationService";
import env from "./env";

export const serviceLogout = () => {
  const service = new AuthenticationService(env.API ?? "");
  if (!service) {
    return;
  }
  service
    .logout()
    .then((result) => {
      window.location.href = result.returnUrl ?? "/";
    })
    .catch(() => {
      window.location.href = "/";
    });
};
