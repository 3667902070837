import axios from "axios";
import {
  UserClient,
  IUserClient,
  UserData,
} from "../client/apiGatewayClient.generated";
import { UserDomainModel } from "../domain/UserDomainModel";
import { UserLogoutResultDomainModel } from "../domain/UserLogoutResultDomainModel";
import { UserLoginResultDomainModel } from "../domain/UserLoginResultDomainModel";
import { PersoonClient } from "../client/persoonsClient.Generated";

export class AuthenticationService {
  private readonly userClient: IUserClient;

  constructor(private readonly url: string) {
    this.userClient = new UserClient(url, axios);
  }

  login = async (returnUrl: string): Promise<UserLoginResultDomainModel> => {
    const instance = axios.create({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    const client = new UserClient(this.url, instance);
    return client.login(returnUrl).then(
      () => ({ succeeded: true, redirect: null }),
      (response) => {
        if (response?.status === 401) {
          return {
            succeeded: false,
            redirect: response?.headers?.location ?? null,
          };
        }

        return { succeeded: false, redirect: null };
      }
    );
  };

  getUserData = (): Promise<UserData | null> => {
    const userClient = new UserClient(this.url, axios);
    return userClient
      .data()
      .then((user) => user)
      .catch(() => null);
  };

  getUser = (): Promise<UserDomainModel | null> => {
    const persoonClient = new PersoonClient(this.url, axios);
    return persoonClient
      .persoon()
      .then((user) => {
        const result = new UserDomainModel();

        result.naam = `${user.voorletters ?? ""} ${user.naam ?? ""}`;

        return result;
      })
      .catch(() => null);
  };

  logout = (): Promise<UserLogoutResultDomainModel> =>
    // eslint-disable-next-line implicit-arrow-linebreak
    this.userClient
      .logout()
      .then((response) => ({
        result: response.resultaat === true ?? false,
        returnUrl: response.returnUrl ?? null,
      }))
      .catch(() => ({
        result: false,
        returnUrl: null,
      }));
}
