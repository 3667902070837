import * as React from "react";

interface IApplicationContextProps {
  children: React.ReactNode;
  basePath: string;
}

export const AppContext = React.createContext<string>("");

export const AppContextProvider = (props: IApplicationContextProps) => (
  <AppContext.Provider value={props.basePath}>{props.children}</AppContext.Provider>
);
