import * as React from "react";
import { AuthenticationService } from "../service/AuthenticationService";

interface IAuthenticationServiceProviderProps {
  children: React.ReactNode;
  service: AuthenticationService;
}
export const AuthenticationServiceContext = React.createContext<AuthenticationService | null>(null);

export const AuthenticationServiceContextProvider = (props: IAuthenticationServiceProviderProps) => (
  <AuthenticationServiceContext.Provider value={props.service}>{props.children}</AuthenticationServiceContext.Provider>
);
