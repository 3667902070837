import * as React from "react";
import { createRoot } from "react-dom/client";
import { IModuleStore } from "redux-dynamic-modules";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { MenuContainer } from "./components/HeaderContainer";
import { MainContentContainer } from "./components/MainContentContainer";
import { AuthenticationServiceContextProvider } from "./authentication/context/AuthenticationServiceContextProvider";
import { AuthenticationService } from "./authentication/service/AuthenticationService";
import { createMijnCAKStore } from "./createMijnCAKStore";

interface ContentPageProps {
  authenticationService: AuthenticationService;
  children: React.ReactNode;
}

export const ContentPage = (props: ContentPageProps) => {
  const mijnCakStore: IModuleStore<unknown> = createMijnCAKStore();
  return (
    <BrowserRouter>
      <CookiesProvider>
        <AuthenticationServiceContextProvider
          service={props.authenticationService}
        >
          <Provider store={mijnCakStore}>
            <MenuContainer />
            <MainContentContainer>
              <div className="dashboard-container unknown-user-container">
                <div className="grid-container row">
                  <div className="content-container col-lg-12 row">
                    <article className="content intro-text pt-5">
                      {props.children}
                    </article>
                  </div>
                  <div className="content-container overzicht" />
                </div>
              </div>
            </MainContentContainer>
          </Provider>
        </AuthenticationServiceContextProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
};

export const renderContentPage = (Component: React.ReactElement) => {
  const appContainer = document.getElementById("app");
  if (appContainer) {
    const appRoot = createRoot(appContainer);
    appRoot.render(Component);
  } else {
    throw new Error("Could not find app element");
  }
};
