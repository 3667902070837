export const mapErrorToStatusCode = (err: any): number => {
  if (err?.status === 418) {
    return 418;
  }

  if (err?.status === 404) {
    return 404;
  }

  if (err?.status === 401) {
    return 401;
  }

  return 500;
};

export const mapErrorToMessage = (err: any): string => {
  if (err?.status === 418) {
    return "CustomerNotFound";
  }
  if (err?.status === 404) {
    return "PageNotFound";
  }

  return "TechnicalError";
};
