export class PiwikService {
  previousPath: string;

  constructor() {
    this.initPiwik();
  }

  initPiwik = () => {
    if (typeof window !== "undefined") {
      window._paq = window._paq || []; // eslint-disable-line  no-underscore-dangle

      PiwikService.push(["enableLinkTracking"]);
    }
  };

  static push(args: string[]) {
    window._paq.push(args); // eslint-disable-line  no-underscore-dangle
  }

  track(pathname: string, search: string) {
    if (typeof window === "undefined") {
      return;
    }
    const currentPath = (pathname + search).replace(/^\//, "");

    if (this.previousPath === currentPath) {
      return;
    }

    if (this.previousPath) {
      PiwikService.push([
        "setReferrerUrl",
        `${window.location.origin}/${this.previousPath}`,
      ]);
    }
    PiwikService.push([
      "setCustomUrl",
      `${window.location.origin}/${currentPath}`,
    ]);
    PiwikService.push(["trackPageView"]);

    this.previousPath = currentPath;
  }
}
