import { AxiosError, AxiosStatic } from "axios";
import { AuthenticationService } from "../service/AuthenticationService";
import env from "../../env";

export const interceptors = {
  initialize: (instance: AxiosStatic) => {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!interceptors.isErrorFromCurrentLoginAttempt(error)) {
          if (error?.response?.status === 401) {
            const authenticationService = new AuthenticationService(
              env.API ?? ""
            );
            return authenticationService.login(window.location.href).then(
              (result) => {
                if (!result.succeeded) {
                  if (result.redirect) {
                    window.location.assign(result.redirect);
                  }
                  throw error;
                }
              }
            );
          }
        }
        throw error;
      });
  },

  isErrorFromCurrentLoginAttempt: (error: AxiosError) =>
    error?.response?.request.responseURL.includes("/api/v1/User/Login") ?? false
};
