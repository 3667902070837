import { Reducer } from "redux";
import { RegelingUrlActions } from "./actions";
import * as types from "./actionTypes";
import { IRegelingenState } from "./state";

export const initialNotificationTypeState: IRegelingenState = {
  regelingen: [],
};

export const RegelingenReducer: Reducer<IRegelingenState, RegelingUrlActions> = (
  state: IRegelingenState = initialNotificationTypeState,
  action: RegelingUrlActions
): IRegelingenState => {
  if (action.type === types.SET_REGELINGURL) {
    return {
      regelingen: [
        ...state.regelingen,
        {
          regelingUrl: action.regelingUrl,
          regelingNaam: action.regelingNaam,
        },
      ],
    };
  }

  return state;
};
