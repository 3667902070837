// Mopinion Pastea.se  start
(function () {
  var id = "ffpsoyX9s8UJT2t3znz2nAiqb0xfVzQFIzQKC9t9";
  var js = document.createElement("script");
  js.setAttribute("type", "text/javascript");
  js.setAttribute("src", "//deploy.mopinion.com/js/pastease.js");
  js.async = true;
  document.getElementsByTagName("head")[0].appendChild(js);
  var t = setInterval(function () {
    try {
      Pastease.load(id);
      clearInterval(t);
    } catch (e) {}
  }, 50);
})();
