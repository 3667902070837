import * as React from "react";
import { useHistory } from "react-router-dom";
import { PiwikService } from "../piwik/PiwikService";

interface IPiwikContainerProps {
  piwik: PiwikService;
}

export const PiwikContainer = (props: IPiwikContainerProps) => {
  useHistory().listen((loc) => {
    props.piwik.track(loc.pathname, loc.search);
  });

  return <></>;
};
