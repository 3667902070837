import SplunkOtelWeb from "@splunk/otel-web";
import SplunkSessionRecorder from "@splunk/otel-web-session-recorder";

export interface IRumConfiguration {
  applicationName: string,
  environment: string,
  token: string | undefined,
  version: string,
  realm?: string,
}

export function initializeRum(configuration: IRumConfiguration) {
    const splunkAccessToken = configuration.token;
    const splunkRealm = configuration.realm ?? "eu0";
    SplunkOtelWeb.init({
      realm: splunkRealm,
      rumAccessToken: splunkAccessToken,
      applicationName: configuration.applicationName,
      version: configuration.version,
      deploymentEnvironment: configuration.environment,
    });
    SplunkSessionRecorder.init({
      realm: splunkRealm,
      rumAccessToken: splunkAccessToken,
    });
}
