import { RegelingNaamEnum } from "@mijncak/shared";
import { PiralPlugin } from "piral-core";
import { Dispatch } from "redux";
import { setActiveRegeling } from "../store/actions";

interface ActiveRegelingConfig {
  dispatch: Dispatch;
}

export interface PiletActiveRegelingApi {
  setActiveRegeling(activeRegeling: RegelingNaamEnum | ""): void;
}

export function createActiveRegelingApi(config: ActiveRegelingConfig): PiralPlugin<PiletActiveRegelingApi> {
  return (_context) =>
    (_api, _target): PiletActiveRegelingApi => ({
      setActiveRegeling(activeRegeling: RegelingNaamEnum | ""): void {
        config.dispatch(
          setActiveRegeling({
            activeRegeling,
          })
        );
      },
    });
}
