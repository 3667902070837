import axios from "axios";
import {
  IGetFeatureEndpointClient,
  GetFeatureEndpointClient,
} from "../client/apiGatewayClient.generated";

export class FeatureToggleService {
  private readonly featureToggleClient: IGetFeatureEndpointClient;

  constructor(url: string) {
    this.featureToggleClient = new GetFeatureEndpointClient(url, axios);
  }

  getFeatureToggle = (name: string): Promise<boolean> =>
    new Promise((resolve, _reject) => {
      this.featureToggleClient.feature(name).then(
        (feature) => resolve(feature.enabled),
        () => {
          resolve(false);
        }
      );
    });
}
