import { RegelingEnum } from "@mijncak/shared";
import { PiralPlugin } from "piral-core";

interface RegelingenConfig {}

export interface PiletRegelingenApi {
  heeftRegeling(type: RegelingEnum): boolean;
  aantalRegelingen(): number;
}

export function createRegelingenApi(_config: RegelingenConfig = {}): PiralPlugin<RegelingenConfig> {
  return (_context) =>
    (api, _target): PiletRegelingenApi => ({
      heeftRegeling(type: RegelingEnum) {
        const regelingenPermissions = api.getUser()?.permissions.regelingen;

        const regelingen: string[] = regelingenPermissions ? (regelingenPermissions as string[]) : [];

        return regelingen.includes(type);
      },

      aantalRegelingen() {
        return api.getUser()?.permissions.regelingen.length;
      },
    });
}
