import * as React from "react";

export const ContentNoClientPage = () => (
  <>
    <h1 className="pl-0">
      Inloggen lukt niet
    </h1>
    <p className="intro">
      U kunt alleen inloggen als u klant bent bij het CAK.
    </p>
    <p>
      Bent u klant en kunt u toch niet inloggen? Probeer het dan op een later moment opnieuw.
    </p>
    <p>

      Lukt het inloggen dan weer niet? Dan kijken we graag met u mee. Bel ons op <u><a href="tel:0800 0087">0800 – 0087</a></u> (gratis). Wij zijn bereikbaar <u>van maandag tot en met vrijdag</u> van 8.30 tot <u>17.00 uur</u>. Houd uw burgerservicenummer (BSN) bij de hand. Wij kunnen u dan sneller helpen.
    </p>
  </>
  );
