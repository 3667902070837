import { ActiveRegelingReducer } from "../plugins/activeRegeling/store/reducer";
import { IActiveRegelingState } from "../plugins/activeRegeling/store/state";
import { gebruikersNaamReducer } from "../plugins/gebruikersnaam/store/gebruikersnaam/reducer";
import { IGebruikersNaamState } from "../plugins/gebruikersnaam/store/gebruikersnaam/state";
import { RegelingenReducer } from "../plugins/regelingUrl/store/reducer";
import { IRegelingenState } from "../plugins/regelingUrl/store/state";

const allReducers = {
  gebruikersNaam: gebruikersNaamReducer,
  activeRegeling: ActiveRegelingReducer,
  regelingen: RegelingenReducer,
};

export default allReducers;
export interface ApplicationState {
  gebruikersNaam: IGebruikersNaamState;
  activeRegeling: IActiveRegelingState;
  regelingen: IRegelingenState;
}
