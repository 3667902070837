import axios, { AxiosError } from "axios";
import { IRegelingenClient, RegelingenClient } from "../client/client.generated";
import { mapErrorToMessage, mapErrorToStatusCode } from "../../utils/errorUtils";

export class RegelingenService {
  private readonly client: IRegelingenClient;

  constructor(url: string) {
    this.client = new RegelingenClient(url, axios);
  }

  getRegelingen = (): Promise<string[] | null> =>
    new Promise((resolve, reject) => {
      this.client.regelingen().then(
        (regelingen) => resolve((regelingen ?? []).map((regeling) => regeling.naam ?? "")),
        (error) => {
          const statusCode = mapErrorToStatusCode(error);
          const errorMessage = mapErrorToMessage(error);
          const errorWithStatus: AxiosError = {
            ...error,
            status: statusCode,
            message: errorMessage,
          };

          reject(errorWithStatus);
        }
      );
    });
}
