import axios from "axios";
import {
  GetFeaturesEndpointClient,
  IGetFeaturesEndpointClient,
} from "../client/apiGatewayClient.generated";
import { FeatureToggleDomainModel } from "../domain/FeatureToggleDomainModel";

export class GetAllFeaturesService {
  private readonly allFeaturesClient: IGetFeaturesEndpointClient;

  constructor(url: string) {
    this.allFeaturesClient = new GetFeaturesEndpointClient(url, axios);
  }

  getAllFeatureToggles = (): Promise<FeatureToggleDomainModel[]> =>
    new Promise((resolve, _reject) => {
      this.allFeaturesClient.all().then(
        (features) => resolve(features),
        () => {
          resolve([]);
        }
      );
    });
}
