import { ActiveRegelingDomainModel } from "../domain/ActiveRegelingDomainModel";
import * as types from "./actionTypes";

export type ActiveRegelingActions = {
  type: typeof types.SET_ACTIVEREGELING;
  activeRegeling: ActiveRegelingDomainModel;
};

export function setActiveRegeling(activeRegeling: ActiveRegelingDomainModel): ActiveRegelingActions {
  return {
    type: types.SET_ACTIVEREGELING,
    activeRegeling,
  };
}
