import { Reducer } from "redux";
import * as types from "./actionTypes";
import { GebruikersNaamActions } from "./actions";
import { IGebruikersNaamState } from "./state";

export const initialNotificationTypeState: IGebruikersNaamState = {
  naam: ""
};

export const gebruikersNaamReducer: Reducer<IGebruikersNaamState, GebruikersNaamActions> = (
  state: IGebruikersNaamState = initialNotificationTypeState,
  action: GebruikersNaamActions
): IGebruikersNaamState => {
  if (action.type === types.SET_GEBRUIKERSNAAM) {
    return {
     naam: action.gebruikersNaam.naam
    };
  }

  return state;
};
