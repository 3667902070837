import * as React from "react";

export const ContentNotFoundPage = () => (
  <>
    <h1 className="pl-0">U kunt niet inloggen</h1>
    <p>
      Mijn CAK is beschikbaar voor klanten die:
    </p>
    <ul>
      <li>
        Wlz-zorg krijgen,
      </li>
      <li>
        ondersteuning ontvangen vanuit de Wmo, of
      </li>
      <li>
        beschermd wonen.
      </li>
    </ul>
  </>
  );
