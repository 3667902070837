import { PiralPlugin } from "piral-core";
import { FeatureToggleDomainModel } from "../../../authentication/domain/FeatureToggleDomainModel";
import { FeatureToggleService } from "../../../authentication/service/FeatureToggleService";

export interface PiletFeatureToggleApi {
  getWlzFeatureToggle: () => boolean;
  getFormsFeatureToggle: () => boolean;
  getIdealV1FeatureToggle: () => boolean;
  getIdealV2FeatureToggle: () => boolean;
  getZaakSysteemFeatureToggle: () => boolean;
  allFeaturesToggle: () => FeatureToggleDomainModel[];
  getFeatureToggle: (name: string) => Promise<boolean>;
}

export function createFeatureToggleApi(
  allFeatureToggle: FeatureToggleDomainModel[],
  apiUrl: string
): PiralPlugin<PiletFeatureToggleApi> {
  return (_context) =>
    (_api, _target): PiletFeatureToggleApi => ({
      getWlzFeatureToggle: () => true,
      getFormsFeatureToggle: () => true,
      getIdealV2FeatureToggle: () => true,
      getIdealV1FeatureToggle: () => false,
      getZaakSysteemFeatureToggle: () => true,
      allFeaturesToggle: () => allFeatureToggle,
      getFeatureToggle: (name: string) => {
        const service = new FeatureToggleService(apiUrl);
        return service.getFeatureToggle(name);
      },
    });
}
