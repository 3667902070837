import * as React from "react";
import { AuthenticationService } from "./authentication/service/AuthenticationService";
import { ContentPage, renderContentPage } from "./ContentPage";
import { ContentNotFoundPage } from "./utils/ContentNotFoundPage";
import { ContentErrorPage } from "./utils/ContentErrorPage";
import { ContentNoClientPage } from "./utils/ContentNoClientPage";

interface ErrorPageProps {
  authenticationService: AuthenticationService;
  statusCode: number;
}

export const ErrorPage = (props: ErrorPageProps) => (
  <ContentPage authenticationService={props.authenticationService}>
    {props.statusCode === 404 && <ContentNotFoundPage />}
    {props.statusCode === 418 && <ContentNoClientPage />}
    {props.statusCode === 500 && <ContentErrorPage />}
  </ContentPage>
);

export const renderErrorPage = (service: AuthenticationService, statusCode: number) =>
renderContentPage(<ErrorPage authenticationService={service} statusCode={statusCode} />);
