import { IModuleStore } from "redux-dynamic-modules";
import { PiletApi, PiletMetadata } from "piral-core";
import { PiletRegelingenApi } from "./regelingen/extendApi/extendApi";
import { PiletAuthenticatieApi } from "./authentication/extendApi/extendApi";
import { PiletGebruikersNaamApi } from "./plugins/gebruikersnaam/extendApi/PiletGebruikerExtendApi";
import { PiletActiveRegelingApi } from "./plugins/activeRegeling/extendApi/PiletActiveRegelingExtendApi";
import { PiletRegelingUrlApi } from "./plugins/regelingUrl/extendUrl/PiletRegelingUrlExtendApi";
import { PiletFeatureToggleApi } from "./plugins/featureToggle/extendApi/FeatureToggleExtendApi";

const createMyShellApi = (_store: IModuleStore<unknown>, _target: PiletMetadata) => ({});

export function extendApi(store: IModuleStore<unknown>) {
  return () => (api: PiletApi, target: PiletMetadata) => ({
    ...api,
    ...createMyShellApi(store, target),
  });
}

export type MijnCakApi = ReturnType<typeof createMyShellApi>;

declare module "piral-core/lib/types/custom" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PiletCustomApi
    extends MijnCakApi,
      PiletRegelingenApi,
      PiletAuthenticatieApi,
      PiletGebruikersNaamApi,
      PiletActiveRegelingApi,
      PiletRegelingUrlApi,
      PiletFeatureToggleApi {}
}
