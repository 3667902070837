import axios from "axios";
import "babel-polyfill";
import { authenticate } from "./authenticate";
import { AuthenticationService } from "./authentication/service/AuthenticationService";
import { RegelingenService } from "./regelingen/service/RegelingenService";
import { interceptors } from "./authentication/interceptors/interceptors";
import { GetAllFeaturesService } from "./authentication/service/AllToggleFeaturesService";
import { initializeRum } from "./initializeRum";
import env from "./env";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _paq: any[];
  }
}

if (env.RUM_ENABLED) {
  initializeRum({
    token: env.RUM_TOKEN,
    applicationName: "mijncak",
    environment: env.RUM_DEPLOYMENT_ENV,
    version: env.BUILD_PCKG_VERSION
  });
}

axios.defaults.withCredentials = env.AUTH_ENABLED;

interceptors.initialize(axios);

const authenticationService = new AuthenticationService(env.API ?? "");
const regelingenService = new RegelingenService(env.API ?? "");
const allFeatureToggleService = new GetAllFeaturesService(
  env.API ?? ""
);

authenticate(
  authenticationService,
  regelingenService,
  allFeatureToggleService
);
