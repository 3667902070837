import * as React from "react";
import { ComponentsState, ErrorComponentsState } from "piral";
import { Link } from "react-router-dom";
import { MenuContainer, MenuItem } from "./components/HeaderContainer";
import { MainContentContainer } from "./components/MainContentContainer";

export const errors: Partial<ErrorComponentsState> = {
  not_found: () => (
    <div>
      <p className="error">Helaas, de pagina die u zoekt is niet gevonden.</p>
      <p>
        Ga naar <Link to="/">de dashboard pagina</Link>.
      </p>
    </div>
  ),
};

export const layout: Partial<ComponentsState> = {
  DashboardContainer: ({ children }) => (
    <div className="dashboard-container">
      <div className="grid-container row">
        <div className="mijncak-welcome-container col-lg-12 row">
          <div className="intro">
            <p>Kies hieronder één van de voor u actieve regelingen.</p>
          </div>
        </div>
        <div className="content-container overzicht">{children}</div>
      </div>
    </div>
  ),
  DashboardTile: ({ children }) => <div className="col-lg-4">{children}</div>,
  Layout: ({ children }) => (
    <>
      <MenuContainer />
      <MainContentContainer>{children}</MainContentContainer>
    </>
  ),
  MenuContainer: ({ children }) => <>{children}</>,
  MenuItem,
};
