import { PiralPlugin } from "piral-core";
import { AxiosStatic } from "axios";
import { interceptors } from "../interceptors/interceptors";

interface AuthenticatieConfig {}

export interface PiletAuthenticatieApi {
  setInterceptors(instance: AxiosStatic): void;
}

export function createAuthenticatieApi(_config: AuthenticatieConfig = {}): PiralPlugin<AuthenticatieConfig> {
  return (_context) => (_api, _target): PiletAuthenticatieApi => ({
    setInterceptors(instance: AxiosStatic) {
      interceptors.initialize(instance);
    }
  });
}
