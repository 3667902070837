import * as types from "./actionTypes";

export type RegelingUrlActions = {
  type: typeof types.SET_REGELINGURL;
  regelingUrl: string;
  regelingNaam: string;
};

export function setRegelingUrl(regelingUrl: string, regelingNaam: string): RegelingUrlActions {
  return {
    type: types.SET_REGELINGURL,
    regelingUrl,
    regelingNaam,
  };
}
