import * as React from "react";
import {
  createInstance,
  SetLayout,
  SetErrors,
  Piral,
  createMenuApi,
} from "piral";
import { createRoot } from "react-dom/client";
import { createDashboardApi, Dashboard } from "piral-dashboard";
import { createAuthApi } from "piral-auth";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { IModuleStore } from "redux-dynamic-modules";
import { RegelingEnum } from "@mijncak/shared";
import {
  ActivityTracker,
  ExtendSessionPopupContainer,
  ActivityTimerContextProvider,
} from "@shared/session-popup";
import { extendApi } from "./extendApi";
import { layout, errors } from "./layout";
import { AuthenticationService } from "./authentication/service/AuthenticationService";
import { AuthenticationServiceContextProvider } from "./authentication/context/AuthenticationServiceContextProvider";
import { createRegelingenApi } from "./regelingen/extendApi/extendApi";
import { createAuthenticatieApi } from "./authentication/extendApi/extendApi";
import { PiwikContainer } from "./components/PiwikContainer";
import { PiwikService } from "./piwik/PiwikService";
import { RegelingenContextProvider } from "./authentication/context/RegelingenContextProvider";
import { createGebruikersNaamApi } from "./plugins/gebruikersnaam/extendApi/PiletGebruikerExtendApi";
import { createActiveRegelingApi } from "./plugins/activeRegeling/extendApi/PiletActiveRegelingExtendApi";
import { createRegelingUrlApi } from "./plugins/regelingUrl/extendUrl/PiletRegelingUrlExtendApi";
import { createFeatureToggleApi } from "./plugins/featureToggle/extendApi/FeatureToggleExtendApi";
import { AppContextProvider } from "./components/context/AppContextProvider";
import { createMijnCAKStore } from "./createMijnCAKStore";
import { FeatureToggleDomainModel } from "./authentication/domain/FeatureToggleDomainModel";
import { serviceLogout } from "./serviceLogout";
import { extendSession } from "./extendSession";
import env from "./env";

export const removeTrailingSlash = (path: string) => {
  if (path && path.substring(path.length - 1) === "/") {
    return path.slice(0, -1);
  }

  return path;
};

const basicDataProperties = {
  owner: "",
  target: "memory" as const,
  expires: -1,
};

const language = {
  language: {
    available: ["nl"],
    selected: "nl",
  },
};

const getInstanceProperties = (basePath: string) => ({
  state: {
    data: {
      "base-path": {
        value: basePath,
        ...basicDataProperties,
      },
      "api-path": {
        value: env.API ?? "",
        ...basicDataProperties,
      },
      "api-auth-enabled": {
        value: env.AUTH_ENABLED ?? false,
        ...basicDataProperties,
      },
    },
    ...language,
  },
});

const getUserObject = (regelingen: string[] | null) => ({
  user: {
    id: "",
    lastName: "",
    features: {},
    firstName: "",
    language: "",
    mail: "",
    permissions: {
      regelingen: regelingen ?? [],
    },
  },
});

const sessionTimerSettings = {
  extendSessionTimerDuration: 10 * 60 * 1000,
  extendSessionShowPopupDuration: 5 * 60,
  autoLogoutSessionTimerDuration: 15 * 60 * 1000,
};

interface IRenderAppProps {
  regelingen: string[] | null;
  authenticationService: AuthenticationService;
  allFeaturesToggle: FeatureToggleDomainModel[];
}

export const RenderApp = (props: IRenderAppProps): JSX.Element => {
  const mijnCakStore: IModuleStore<unknown> = createMijnCAKStore();

  if (!props.regelingen || props.regelingen.length === 0) {
    window.location.href = "/ophalen-klantgegevens-mislukt";
    return <></>;
  }

  const isRegelingEnabled = (regeling?: string | null) =>
    regeling === RegelingEnum.WMO_SINCE_2020 ||
    regeling === RegelingEnum.WLZ ||
    (regeling === RegelingEnum.BTL && props.allFeaturesToggle.find((feature) => feature.name === "mijncak-regeling-btl")?.enabled);

  const implementedRegelingen =
    props.regelingen?.filter(isRegelingEnabled) ?? null;

  const basePath = removeTrailingSlash(env.APPLICATION_PATH);

  const instance = createInstance({
    ...getInstanceProperties(basePath),
    plugins: [
      createRegelingenApi(),
      createAuthApi(getUserObject(implementedRegelingen)),
      createAuthenticatieApi(),
      createMenuApi(),
      createGebruikersNaamApi({ dispatch: mijnCakStore.dispatch }),
      createActiveRegelingApi({ dispatch: mijnCakStore.dispatch }),
      createRegelingUrlApi({ dispatch: mijnCakStore.dispatch }),
      createFeatureToggleApi(props.allFeaturesToggle, env.API ?? ""),
      createDashboardApi({ routes: ["/dashboard"] }),
      extendApi(mijnCakStore),
    ],
    requestPilets() {
      return fetch(`${env.API}/api/v1/pilet`)
        .then((res) => res.json())
        .then((res) => res.items);
    },
  });

  if (!implementedRegelingen || implementedRegelingen.length === 0) {
    instance.root.registerPage(env.APPLICATION_PATH, () => (
      <>Er zijn geen regelingen voor u gevonden.</>
    ));
  } else if (implementedRegelingen && implementedRegelingen.length > 1) {
    instance.root.registerPage(env.APPLICATION_PATH, Dashboard);
  } else {
    instance.root.registerPage(env.APPLICATION_PATH, () => <></>);
  }

  return (
    <AppContextProvider basePath={basePath}>
      <CookiesProvider>
        <AuthenticationServiceContextProvider
          service={props.authenticationService}
        >
          <RegelingenContextProvider regelingen={implementedRegelingen}>
            <ActivityTimerContextProvider>
              <ActivityTracker />
              <ExtendSessionPopupContainer
                handleLogout={serviceLogout}
                sessionTimerSettings={sessionTimerSettings}
                extendSession={extendSession}
                extendSessionInfoText="Uw sessie verloopt bijna. Klik op 'Verlengen' om uw sessie te verlengen."
              />
              <Provider store={mijnCakStore}>
                <Piral instance={instance}>
                  <SetLayout layout={layout} />
                  <SetErrors errors={errors} />
                  <PiwikContainer piwik={new PiwikService()} />
                </Piral>
              </Provider>
            </ActivityTimerContextProvider>
          </RegelingenContextProvider>
        </AuthenticationServiceContextProvider>
      </CookiesProvider>
    </AppContextProvider>
  );
};

export const RenderMijnCAK = (
  regelingen: string[] | null,
  authenticationService: AuthenticationService,
  allFeaturesToggle: FeatureToggleDomainModel[]
) => {
  const container = document.getElementById("app");
  if (container) {
    const root = createRoot(container);
    root.render(
      <RenderApp
        regelingen={regelingen}
        authenticationService={authenticationService}
        allFeaturesToggle={allFeaturesToggle}
      />
    );
  } else {
    throw new Error("Could not find container");
  }
};
